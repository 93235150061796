body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #ffffff;
}

::-webkit-scrollbar-thumb {
    background: #555;
}

::-webkit-scrollbar-thumb:hover {
    background: #767676;
}

.gray {
    background-color: #0d0b26 !important;
}

.widget-title {
    margin-bottom: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e1e7ec;
    border-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}